import * as React from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { MapContainer, TileLayer } from 'react-leaflet'
import ReactLeafletKml from 'react-leaflet-kml'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { CDBTable, CDBTableBody, CDBContainer, CDBSpinner } from 'cdbreact'

function TrackableMap () {
  const { tid } = useParams()
  const [kml, setKml] = React.useState(null)
  const [data, setData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setIsLoading(true)
    const config = {
      headers: {
        'Content-Type': 'application/xml'
      }
    }
    Promise.all([
      axios.get(`https://geocaching.ohrj.social/trackables/${tid}.kml`, config),
      axios.get(`https://api.geocaching.ohrj.social/trackables/${tid}`)
    ]).then(([kmlResponse, dataResponse]) => {
      const parser = new DOMParser()
      const kml = parser.parseFromString(kmlResponse.data, 'text/xml')
      setKml(kml)
      setData(dataResponse.data)
    }).catch(error => {
      console.error('Error fetching data:', error)
    }).finally(() => {
      setIsLoading(false)
    })
  }, [tid]) // Correct placement of dependencies array

  if (isLoading) {
    return (
            <CDBContainer>
                <CDBSpinner multicolor />
            </CDBContainer>
    )
  }

  return (
        <div>
            <Container>
                <Col>
                    <Row>
                        <Col>
                            <h4>Trackable Details</h4>
                            {data && (
                                <CDBContainer>
                                    <CDBTable>
                                        <CDBTableBody>
                                            <tr>
                                                <td>Trackable ID</td>
                                                <td>{data.id}</td>
                                            </tr>
                                            <tr>
                                                <td>Name</td>
                                                <td>{data.trackable_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Distance Traveled</td>
                                                <td>{data.trackable_distance} miles</td>
                                            </tr>
                                            <tr>
                                                <td>Release Date</td>
                                                <td>{data.trackable_release_date}</td>
                                            </tr>
                                            <tr>
                                                <td>Origin</td>
                                                <td>{data.trackable_origin}</td>
                                            </tr>
                                        </CDBTableBody>
                                    </CDBTable>
                                </CDBContainer>
                            )}
                        </Col>
                        <Col>
                            <h4>Latest Log</h4>
                            {data && (
                                <CDBContainer>
                                    <CDBTable>
                                        <CDBTableBody>
                                            <tr>
                                                <td>Log Type</td>
                                                <td>{data.log_type}</td>
                                            </tr>
                                            <tr>
                                                <td>Date</td>
                                                <td>{data.log_visited}</td>
                                            </tr>
                                            <tr>
                                                <td>Finder</td>
                                                <td>{data.log_author}</td>
                                            </tr>
                                            <tr>
                                                <td>Type</td>
                                                <td>{data.log_type}</td>
                                            </tr>
                                        </CDBTableBody>
                                    </CDBTable>
                                </CDBContainer>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <MapContainer
                            style={{ height: '500px', width: '100%' }}
                            zoom={5}
                            center={[55.3781, 3.4360]}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            {kml && <ReactLeafletKml kml={kml} />}
                        </MapContainer>
                    </Row>
                </Col>
            </Container>
        </div>
  )
}

export default TrackableMap

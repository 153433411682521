function formatDate (inputDate) {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  // Parse the input date
  const date = new Date(inputDate)

  // Extract the day, month, and year from the date
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  // Determine the correct ordinal suffix for the day
  const ordinalSuffix = ((day) => {
    if (day > 3 && day < 21) return 'th'
    switch (day % 10) {
      case 1: return 'st'
      case 2: return 'nd'
      case 3: return 'rd'
      default: return 'th'
    }
  })(day)

  // Format the date as 'DDth March YYYY' or similar
  return `${day}${ordinalSuffix} ${months[month]} ${year}`
}

export default formatDate

import { React, useEffect, useState } from 'react'
import axios from 'axios'
import { CDBCircularProgress, CDBContainer } from 'cdbreact'
import { Table } from 'react-bootstrap'

function SeriesProgress () {
  const [seriesDetails, setSeriesDetails] = useState([])

  useEffect(() => {
    const fetchSeries = async () => {
      try {
        const seriesResponse = await axios.get('https://api.geocaching.ohrj.social/series')
        const seriesList = seriesResponse.data
        const detailsPromises = seriesList.map((item) =>
          axios.get(`https://api.geocaching.ohrj.social/series/${item}`).then(response => response.data)
        )
        const details = await Promise.all(detailsPromises)
        setSeriesDetails(details)
      } catch (error) {
        console.error('Error fetching series', error)
      }
    }

    fetchSeries()
  }, [])

  return (
        <div>
            <Table>
                <thead>
                <tr>
                    <th>Series</th>
                    <th>Found</th>
                    <th>Total</th>
                    <th>Last Updated</th>
                    <th>Progress</th>
                </tr>
                </thead>

            {seriesDetails.map((series) => (
                <tbody key={series.friendly_name}>
                <tr>
                    <td>{series.friendly_name}</td>
                    <td>{series.caches_found}</td>
                    <td>{series.caches}</td>
                    <td>{series.last_updated}</td>
                    <td>
                        <CDBContainer>
                            <CDBCircularProgress
                                value={series.percentage_found}
                                size="sm"
                                text={`${series.percentage_found}%`}
                                color={series.percentage_found > 50 ? 'success' : 'danger'}
                            />
                        </CDBContainer>
                    </td>
                </tr>
                </tbody>
            ))}
            </Table>
        </div>
  )
}

export default SeriesProgress

import { React, useEffect, useState } from 'react'
import { FirstCacheCard } from '../components/firstCaches'

function FirstCaches () {
  const [data, setData] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const response = await fetch('https://api.geocaching.ohrj.social/stat/first_cache')
      const jsonData = await response.json()
      setData(jsonData)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const chunkData = (arr, chunkSize) => {
    return Array.from({ length: Math.ceil(arr.length / chunkSize) }, (_, index) => {
      const start = index * chunkSize
      return arr.slice(start, start + chunkSize)
    })
  }

  const chunkedData = chunkData(Object.entries(data), 3)

  return (
        <div style={{ paddingTop: '20px' }}>
            <h1>First Caches Per Type</h1>
            {chunkedData.map((chunk, rowIndex) =>
                <div key={rowIndex} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    {chunk.map(([cacheType, cacheData], index) => (
                        <div
                            key={cacheType}
                            style={{
                              animation: `fadeIn 1s ease ${index * 0.5}s forwards`,
                              opacity: 0 // Start with the element invisible
                            }}
                        >
                            <FirstCacheCard
                                cacheName={cacheData.cache_name}
                                cacheType={cacheType}
                                cacheId={cacheData.id}
                                cacheFound={cacheData.date_found}
                                cacheDifficulty={cacheData.cache_difficulty}
                                cacheSize={cacheData.cache_size}
                                cacheTerrain={cacheData.cache_terrain}
                                cacheAuthor={cacheData.cache_author}
                                cacheCountry={cacheData.country}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
  )
}

export default FirstCaches

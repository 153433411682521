import 'bootstrap/dist/css/bootstrap.min.css'
import { React, useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js'
import { Bar } from 'react-chartjs-2'

function AttrBarChart () {
  ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement)

  const attrLabels = []
  const attrData = []
  const [data, setData] = useState([])
  useEffect(() => {
    attributes()
  }, [])

  const attributes = async () => {
    const url = 'https://api.geocaching.ohrj.social/stat/attributes'
    const response = await fetch(url)
    setData(await response.json())
  }
  Object.keys(data).forEach(function (key) {
    if (data[key] !== 0) {
      attrData.push(data[key])
      attrLabels.push(key.charAt(0).toUpperCase() + key.slice(1).toLowerCase())
    }
  })
  const chartData = {

    labels: attrLabels,
    datasets: [
      {
        label: 'Cache Attributes',
        data: attrData
        // backgroundColor: colourList
      }
    ]
  }

  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    }
  }
  return (
        <div>
            <p><Bar data={chartData} options={chartOptions} height={300} /></p>
        </div>
  )
}

export default AttrBarChart

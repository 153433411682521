import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  CDBContainer,
  CDBCard,
  CDBCardBody,
  CDBTable,
  CDBTableBody,
  CDBProgress
} from 'cdbreact'
import formatDate from '../functions/dateFormatter'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

export const FirstCacheCard = ({ cacheName, cacheType, cacheId, cacheFound, cacheDifficulty, cacheSize, cacheAuthor, cacheTerrain, cacheCountry }) => {
  const getSizeValue = (size) => {
    switch (size) {
      case 'virtual': return 0
      case 'micro': return 1
      case 'small': return 2
      case 'regular': return 3
      case 'large': return 4
      case 'not chosen': return 0
      case 'other': return 0
      case null: return 0
      default: return -1
    }
  }

  return (
        <CDBContainer>
            <CDBCard style={{ width: '100%' }} className="card">
                <CDBCardBody style={{ position: 'relative' }}>
                    <img
                        src={`/cache_logos/${cacheType}.png`}
                        alt={cacheType}
                        style={{
                          position: 'right',
                          bottom: '5px',
                          right: '5px',
                          width: '50px',
                          height: 'auto'
                        }}
                    />
                    <CDBTable>
                        <CDBTableBody>
                            <tr>
                                <td>Cache Name</td>
                                <td>{cacheName}</td>
                            </tr>
                            <tr>
                                <td>Cache ID</td>
                                <td>{cacheId}</td>
                            </tr>
                            <tr>
                                <td>Cache Author</td>
                                <td>{cacheAuthor}</td>
                            </tr>
                            <tr>
                                <td>Cache Found Date</td>
                                <td>{formatDate(cacheFound)}</td>
                            </tr>
                            <tr>
                                <td>Cache Difficulty</td>
                                <td><CDBProgress colours="warning" max="5" value={cacheDifficulty} text={cacheDifficulty}/> </td>
                            </tr>
                            <tr>
                                <td>Cache Size</td>
                                <td><CDBProgress colours="warning" max="7" value={getSizeValue(cacheSize)} text={cacheSize}/> </td>
                            </tr>
                            <tr>
                                <td>Cache Terrain</td>
                                <td><CDBProgress colours="warning" max="5" value={cacheTerrain}
                                                 text={cacheTerrain}/></td>
                            </tr>
                            <tr>
                                <td>Country</td>
                                {cacheCountry === null
                                  ? (
                                    <td>
                                      <FontAwesomeIcon icon={faCircleQuestion} />
                                    </td>
                                    )
                                  : (
                                    <td><img src={`/flags/${cacheCountry}.svg`} alt="Flag"/></td>
                                    )
                                }
                            </tr>

                        </CDBTableBody>
                    </CDBTable>
                </CDBCardBody>
            </CDBCard>
        </CDBContainer>
  )
}

FirstCacheCard.propTypes = {
  cacheName: PropTypes.string,
  cacheType: PropTypes.string,
  cacheId: PropTypes.number,
  cacheFound: PropTypes.string,
  cacheDifficulty: PropTypes.number,
  cacheSize: PropTypes.string,
  cacheTerrain: PropTypes.number,
  cacheAuthor: PropTypes.string,
  cacheCountry: PropTypes.string
}

import 'bootstrap/dist/css/bootstrap.min.css'
import { React, useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

function StatDoughnut (props) {
  const colourList = [
    'rgba(137, 26, 175, 1)',
    'rgba(73, 74, 177, 1)',
    'rgba(115, 98, 121, 1)',
    'rgba(137, 32, 93, 1)',
    'rgba(39, 91, 163, 1)',
    'rgba(133, 93, 53, 1)',
    'rgba(140, 192, 52, 1)',
    'rgba(17, 1, 161, 1)',
    'rgba(101, 134, 146, 1)',
    'rgba(50, 82, 46, 1)',
    'rgba(101, 128, 61, 1)',
    'rgba(178, 79, 119, 1)',
    'rgba(51, 96, 189, 1)',
    'rgba(23, 83, 193, 1)',
    'rgba(174, 47, 143, 1)',
    'rgba(54, 62, 132, 1)',
    'rgba(12, 79, 77, 1)',
    'rgba(37, 53, 71, 1)',
    'rgba(68, 40, 11, 1)',
    'rgba(128, 18, 140, 1)',
    'rgba(36, 159, 63, 1)',
    'rgba(49, 15, 8, 1)',
    'rgba(149, 88, 33, 1)',
    'rgba(145, 96, 105, 1)'
  ]
  ChartJS.register(ArcElement, Tooltip, Legend)

  const chartLabels = []
  const chartData = []
  const [data, setData] = useState([])
  useEffect(() => {
    size()
  }, [])

  const size = async () => {
    // eslint-disable-next-line react/prop-types
    const url = `https://api.geocaching.ohrj.social/stat/${props.path}`
    const response = await fetch(url)
    setData(await response.json())
  }
  Object.keys(data).forEach(function (key) {
    chartData.push(data[key])
    chartLabels.push(key)
  })
  const pieData = {
    labels: chartLabels,
    datasets: [
      {
        // eslint-disable-next-line react/prop-types
        label: props.chartLabel,
        data: chartData,
        borderWidth: 1,
        backgroundColor: colourList
      }
    ]
  }
  const chartOptions = {
    cutout: 100,
    plugins: {
      legend: {
        display: false
      }
    }
  }
  return (
      <div>
          <p><Doughnut data={pieData} options={chartOptions} /></p>
      </div>
  )
}

export default StatDoughnut

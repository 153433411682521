import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AttrBarChart from '../sections/attributes'
import CountryTable from '../sections/countryTable'
import UkCountryTable from '../sections/ukCountryTable'
import AuthorTable from '../sections/authorTable'
import SeriesProgress from '../sections/seriesProgress'
import ScatterChart from '../sections/scatterChart'
import StatDoughnut from '../sections/Donut'

function HomePage () {
  return (
        <div>
            <Container>
            <Row>
                <Col>
                    <h1>Geocaching Statistics</h1>
                </Col>
            </Row>
            <Row class="justify-content-md-center">
                <Col xs lg="3">
                    <Row>
                        <h3>Found Vs DNF</h3>
                    </Row>
                    <Row>
                       <StatDoughnut path="found" chartLabel="Found Vs. DNF"/>
                    </Row>
                </Col>
                <Col xs lg="3">
                    <Row>
                        <h3>Caches by Size</h3>
                    </Row>
                    <Row>
                    <ScatterChart path="size" chartLabel="Geocaches by Size"/>
                    </Row>
                </Col>
                <Col xs lg="3">
                    <Row>
                        <h3>Caches by Type</h3>
                    </Row>
                    <Row>
                       <StatDoughnut path="type" chartLabel="Geocaches By Type"/>
                    </Row>
                </Col>
                <Col xs lg="3">
                    <Row>
                        <h3>Cache by Difficulty</h3>
                    </Row>
                    <Row>
                       <ScatterChart path="difficulty" chartLabel="Caches by Difficulty"/>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Cache Attributes</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AttrBarChart/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Country Table</h3>
                </Col>
                <Col>
                    <h3>UK Country Table</h3>
                </Col>
                <Row>
                    <Col>
                        <CountryTable/>
                    </Col>
                    <Col>
                        <UkCountryTable/>
                    </Col>
                </Row>

            </Row>
            <Row>
                <Col>
                    <h3>Top 10 Authors</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AuthorTable/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <h3>Series Progress</h3>
                    </Row>
                    <Row>
                        <SeriesProgress/>
                    </Row>
                </Col>
            </Row>
        </Container>
            </div>
  )
}

export default HomePage

import { React, useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Row } from 'react-bootstrap'
function TrackableOverview () {
  const [data, setData] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const response = await fetch('https://api.geocaching.ohrj.social/stat/trackable_locations')
      const jsonData = await response.json()
      setData(jsonData)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  return (
        <div style={{ paddingTop: '20px' }}>
            <h1>Trackable Overviews</h1>
            <Row>
                        <MapContainer
                            style={{ height: '500px', width: '100%' }}
                            zoom={2}
                            center={[55.3781, 3.4360]}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            {Object.keys(data).map((key) => {
                              const position = [data[key].lat, data[key].lon]
                              // console.log(data[key])
                              return (
                               <Marker key={position} position={position}>
                                <Popup>
                                  {data[key].trackable_id} <br /> <a href={`/trackable/${data[key].trackable_id}`}>Link</a>
                                </Popup>
                              </Marker>
                              )
                            })}
                        </MapContainer>
                    </Row>
        </div>
  )
}

export default TrackableOverview

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from "react-bootstrap/Navbar";
import Container from 'react-bootstrap/Container';
import HomePage from "./pages/home";
import FirstCaches from "./pages/firstCaches";
import TrackableMap from "./sections/trackableMap";
import TrackableOverview from './pages/trackableOverview';
import {
    CDBSidebar,
    CDBSidebarHeader,
    CDBSidebarMenuItem,
    CDBSidebarContent,
    CDBSidebarMenu,
    CDBSidebarSubMenu,
    CDBSidebarFooter,
} from 'cdbreact'
import SiteSideBar from "./components/sidebar";
const tagManagerArgs = {
    gtmId: 'GTM-KZD4T4T5'
}

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
// document.documentElement.setAttribute('data-bs-theme', 'dark');

root.render(
    <React.StrictMode>
        <Router>
            <div style={{display: 'flex', height: '100vh', width: '100%'}}>
                <SiteSideBar/>
                <div style={{flex: 1, padding: '20px', overflowY: 'auto', height: '100vh'}}>

                    <link rel="stylesheet" href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css"/>
                    <Routes>
                        {/*<Route path="/" element={<StatDoughnutPage />} />*/}
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/trackable/:tid" element={<TrackableMap/>}/>
                        <Route path="/trackable/overview" element={<TrackableOverview/>}/>
                        <Route path="/first-caches" element={<FirstCaches/>}/>
                    </Routes>
                </div>
            </div>
        </Router>
    </React.StrictMode>
);

reportWebVitals();

import { React, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'

function UkCountryTable () {
  const [data, setData] = useState([])
  useEffect(() => {
    attributes()
  }, [])
  const attributes = async () => {
    const url = 'https://api.geocaching.ohrj.social/stat/uk_country'
    const response = await fetch(url)
    setData(await response.json())
  }
  return (
        <div>
            <Table>
                <thead>
                <tr>
                    <th></th>
                    <th>Country</th>
                    <th>Count</th>
                </tr>
                </thead>
                {Object.keys(data).map((key) => {
                  let flagImage = 'GB'
                  if (key === 'Scotland') {
                    flagImage = 'GB-SCT'
                  } else if (key === 'England') {
                    flagImage = 'GB-ENG'
                  } else if (key === 'Wales') {
                    flagImage = 'GB-WLS'
                  } else if (key === 'Northern Ireland') {
                    flagImage = 'GB-NIR'
                  }

                  return (
                        <tbody key={key}>
                        <tr>
                            <td><img src={`/flags/${flagImage}.svg`} alt="Flag"/></td>
                            <td>{key}</td>
                            <td>{data[key]}</td>
                        </tr>
                        </tbody>
                  )
                })}
            </Table>
        </div>
  )
}

export default UkCountryTable

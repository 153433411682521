import React from 'react'
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarSubMenu
} from 'cdbreact'
import { NavLink } from 'react-router-dom'
import axios from 'axios'

const SiteSideBar = () => {
  const [data, setData] = React.useState([])
  React.useEffect(() => {
    axios.get('https://api.geocaching.ohrj.social/trackables')
      .then((res) => {
        // Use res.data to get the response data
        setData(res.data)
      })
      .catch((error) => console.error('Error fetching data:', error))
  }, [])
  return (
        <CDBSidebar textColor="#fff" backgroundColor="#333" style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflowY: 'auto' }}>
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>ohheyrj17 Cache Stats</CDBSidebarHeader>
            <CDBSidebarContent>
                <CDBSidebarMenu>
                    <NavLink to="/" exact activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="home">Home</CDBSidebarMenuItem>
                    </NavLink>
                    <CDBSidebarSubMenu title="Stats" icon="chart-line">
                        <NavLink to="/first-caches" exact activeClassName="activeClicked">
                            <CDBSidebarMenuItem>First Caches</CDBSidebarMenuItem>
                        </NavLink>
                    </CDBSidebarSubMenu>
                    <CDBSidebarSubMenu title="Travel Bugs" icon="bug">
                      <NavLink to ="/trackable/overview" exact activeClassName="activeClicked">
                      <CDBSidebarMenuItem>Overview</CDBSidebarMenuItem>
                      </NavLink>
                    {Object.keys(data).map((key) => {
                      return (
                            <NavLink key={data[key].id} to={`/trackable/${data[key].id}`} exact activeClassName="activeClicked">
                                <CDBSidebarMenuItem textFontSize="10px">{data[key].trackable_name}</CDBSidebarMenuItem>
                            </NavLink>
                      )
                    })}
                    </CDBSidebarSubMenu>

                        </CDBSidebarMenu>
            </CDBSidebarContent>
        </CDBSidebar>
  )
}

export default SiteSideBar

// eslint-disable-next-line react/prop-types

import 'bootstrap/dist/css/bootstrap.min.css'
import { React, useEffect, useState } from 'react'
import { Chart as ChartJS, Tooltip, Legend, RadialLinearScale, PointElement, LineElement, Filler } from 'chart.js'
import { Radar } from 'react-chartjs-2'

function ScatterChart (props) {
  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  )
  const chartLabels = []
  const chartData = []
  const [data, setData] = useState([])
  useEffect(() => {
    size()
  }, [])

  const size = async () => {
    // eslint-disable-next-line react/prop-types
    const url = `https://api.geocaching.ohrj.social/stat/${props.path}`
    const response = await fetch(url)
    setData(await response.json())
  }
  Object.keys(data).forEach(function (key) {
    chartData.push(data[key])
    chartLabels.push(key)
  })
  const pieData = {
    labels: chartLabels,
    datasets: [
      {
        // eslint-disable-next-line react/prop-types
        label: props.chartLabel,
        data: chartData,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1
      }
    ]
  }
  return (
      <div>
          <p><Radar data={pieData} /></p>
      </div>
  )
}

export default ScatterChart
